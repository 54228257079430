<template>
  <div>
    <!-- <b-alert
      variant="danger"
      :show="
        () => {
          return typeof produkData !== 'object'
        }
      "
    >
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No produk found with the id. Check
        <b-link class="alert-link" :to="{ name: 'apps-produk-list' }">
          Produk List
        </b-link>
        for other produk.
      </div>
    </b-alert> -->

    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0"
      opacity=".75" rounded="sm">
      <b-row  v-if="produkData !== null">
        <b-col cols="12">
          <template>
            <b-tabs content-class="mt-1">
              <!-- This tabs content will always be mounted -->
              <b-tab title="Info">
                <view-info-card :product-data="produkData" :loading="showLoading" />
              </b-tab>
              <b-tab title="Images">
                <view-image-card v-if="produkData !== null" :product-data="produkData" />
              </b-tab>
              <b-tab title="Reviews">
                <review-list
                  title="Customer Review"
                  :kdProduk="produkData.kd_produk"
                  is-widget
                />
              </b-tab>
              <b-tab title="Transactions" lazy>
                <view-transaction />
              </b-tab>
              <!-- <b-tab title="Flash Sales" lazy /> -->
            </b-tabs>
          </template>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink, BTabs, BTab, BOverlay } from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import ReviewList from '@/views/master/review/List.vue'
import ProdukStoreModule from './produkStoreModule'
import ViewInfoCard from './ViewInfoCard'
import ViewImageCard from './ViewImageCard'
import ViewTransaction from './ViewTransaction.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    ViewInfoCard,
    ViewImageCard,
    InvoiceList,
    ReviewList,
    BOverlay,
    ViewTransaction,
  },
  setup() {
    const produkData = ref(null)
    const showLoading = ref(false)

    const PRODUK_APP_STORE_MODULE_NAME = 'app-produk'

    // Register module
    if (!store.hasModule(PRODUK_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUK_APP_STORE_MODULE_NAME, ProdukStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUK_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUK_APP_STORE_MODULE_NAME)
      }
    })

    const retrieve = () => {
      showLoading.value = true
      store
        .dispatch('app-produk/fetchProduk', {
          id: router.currentRoute.params.id,
        })
        .then(response => {
          produkData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            produkData.value = undefined
          }
        })
        .finally(() => {
          showLoading.value = false
        })
    }

    retrieve()

    return {
      produkData,
      showLoading,
    }
  },
}
</script>

<style></style>
