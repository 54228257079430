<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group label="Kode Produk" label-for="kd_produk">
            <b-form-input
              id="kd_produk"
              v-model="product.kd_produk"
              autofocus
              trim
              placeholder="Kode Produk"
              readonly
            />
          </b-form-group>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nama Produk"
            rules="required"
          >
            <b-form-group label="Nama Produk" label-for="product_name">
              <b-form-input
                id="product_name"
                v-model="product.nama_produk"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nama Produk"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="category"
                v-model="product.kd_kategori"
                label="Nama_Kategori"
                :filterable="false"
                :options="categoryOptions"
                :reduce="
                  e => {
                    return e.Kd_Kategori
                  }
                "
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.Nama_Kategori }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.Nama_Kategori }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Harga"
            rules="required"
          >
            <b-form-group label="Harga" label-for="price">
              <b-form-input
                id="price"
                v-model="product.harga"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Harga"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Berat"
            rules="required|double"
          >
            <b-form-group label="Berat" label-for="weight">
              <b-form-input
                id="weight"
                v-model="product.berat"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Berat"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Diskon"
            rules="required|double"
          >
            <b-form-group label="Diskon" label-for="discount">
              <b-form-input
                id="discount"
                v-model="product.diskon"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Diskon"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Garansi"
            rules="required"
          >
            <b-form-group
              label="Garansi"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="guarantee"
                v-model="product.garansi_id"
                label="nama"
                :filterable="false"
                :options="guaranteeOptions"
                :reduce="
                  e => {
                    return e.id
                  }
                "
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.nama }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.nama }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Available"
            rules="required"
          >
            <b-form-group
              label="Available"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="available"
                v-model="product.avalaible"
                :filterable="false"
                :options="['Yes', 'No']"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Discontinue"
            rules="required"
          >
            <b-form-group
              label="Discontinue"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="discontinue"
                v-model="product.discontinue"
                :filterable="false"
                :options="['Yes', 'No']"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Tags">
            <b-form-group
              label="Tags"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="tags"
                v-model="product.tags"
                multiple
                label="Tags"
                :filterable="false"
                :options="tagOptions"
                :reduce="
                  e => {
                    return e.id
                  }
                "
                @option:deselected="onDeselectTag"
                @option:selected="onSelectTag"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Spesifikasi"
            rules="required"
          >
            <b-form-group label="Spesifikasi" label-for="spesifikasi">
              <ckeditor
                v-model="product.spesifikasi"
                :editor="cekEditorConfig.editor"
                :config="cekEditorConfig.editorConfig"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import CKBox from '@ckeditor/ckeditor5-ckbox/src/ckbox'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Link from '@ckeditor/ckeditor5-link/src/link'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
import List from '@ckeditor/ckeditor5-list/src/list'
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import Mention from '@ckeditor/ckeditor5-mention/src/mention'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import PictureEditing from '@ckeditor/ckeditor5-image/src/pictureediting'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextPartLanguage from '@ckeditor/ckeditor5-language/src/textpartlanguage'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import TodoList from '@ckeditor/ckeditor5-list/src/todolist'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { onMounted, ref, watch } from '@vue/composition-api'
import { required, alphaNum, email, double, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import router from '@/router'
import service from '@/components/Table/service'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BOverlay,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      double,
    }
  },
  setup(props, { emit, root }) {
    const cekEditorConfig = ref({
      editor: ClassicEditor,
      // editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        height: '500px',
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Heading,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          TableToolbar,
          Alignment,
          AutoImage,
          AutoLink,
          CKBox,
          CloudServices,
          Code,
          CodeBlock,
          Essentials,
          FindAndReplace,
          Font,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          ImageInsert,
          ImageResize,
          ImageUpload,
          IndentBlock,
          LinkImage,
          ListProperties,
          Mention,
          PageBreak,
          PasteFromOffice,
          PictureEditing,
          RemoveFormat,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Strikethrough,
          Subscript,
          Superscript,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TextPartLanguage,
          TextTransformation,
          TodoList,
          Underline,
          WordCount,
          SimpleUploadAdapter,
          // LineHeight,
          //  Base64UploadAdapter
        ],
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'selectAll',
            '|',
            'heading',
            '|',
            'removeFormat',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'code',
            'subscript',
            'superscript',
            '|',
            'specialCharacters',
            'horizontalLine',
            'pageBreak',
            '|',
            '-',
            'highlight',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'lineHeight',
            '|',
            'link',
            'blockQuote',
            'insertTable',
            'insertImage',
            'mediaEmbed',
            'codeBlock',
            'htmlEmbed',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'outdent',
            'indent',
            'alignment',
            '|',
            'textPartLanguage',
            '|',
            'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
        // exportPdf: {
        //   stylesheets: [
        //     'EDITOR_STYLES',
        //     // Add your custom styles here
        //     // Read more in the documentation:
        //     // https://ckeditor.com/docs/ckeditor5/latest/features/export-pdf.html#plugin-option
        //   ],
        //   fileName: 'export-pdf-demo.pdf',
        //   converterOptions: {
        //     format: 'Tabloid',
        //     margin_top: '20mm',
        //     margin_bottom: '20mm',
        //     margin_right: '24mm',
        //     margin_left: '24mm',
        //     page_orientation: 'portrait'
        //   },
        //   // PROVIDE CORRECT VALUES HERE:
        //   tokenUrl: 'https://example.com/cs-token-endpoint'
        // },
        // exportWord: {
        //   stylesheets: ['EDITOR_STYLES'],
        //   fileName: 'export-word-demo.docx',
        //   converterOptions: {
        //     format: 'B4',
        //     margin_top: '20mm',
        //     margin_bottom: '20mm',
        //     margin_right: '12mm',
        //     margin_left: '12mm',
        //     page_orientation: 'portrait'
        //   },
        //   // PROVIDE CORRECT VALUES HERE:
        //   tokenUrl: 'https://example.com/cs-token-endpoint'
        // },
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22, 44, 66, 88],
          supportAllValues: true,
        },
        lineHeight: {
          // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
          options: [0.5, 1, 1.5, 2, 2.5],
        },
        htmlEmbed: {
          showPreviews: true,
        },
        image: {
          styles: ['alignCenter', 'alignLeft', 'alignRight'],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null,
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50',
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75',
            },
          ],
          toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            'imageStyle:side',
            '|',
            'resizeImage',
          ],
          insert: {
            integrations: ['insertImageViaUrl'],
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file',
              },
            },
          },
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple',
                '@bears',
                '@brownie',
                '@cake',
                '@cake',
                '@candy',
                '@canes',
                '@chocolate',
                '@cookie',
                '@cotton',
                '@cream',
                '@cupcake',
                '@danish',
                '@donut',
                '@dragée',
                '@fruitcake',
                '@gingerbread',
                '@gummi',
                '@ice',
                '@jelly-o',
                '@liquorice',
                '@macaroon',
                '@marzipan',
                '@oat',
                '@pie',
                '@plum',
                '@pudding',
                '@sesame',
                '@snaps',
                '@soufflé',
                '@sugar',
                '@sweet',
                '@topping',
                '@wafer',
              ],
              minimumCharacters: 1,
            },
          ],
        },
        placeholder: 'Type or paste your content here!',
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
            'toggleTableCaption',
          ],
        },
        wproofreader: {
          // PROVIDE CORRECT VALUE HERE:
          serviceId: 'service ID',
          lang: 'auto',
          srcUrl:
            'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: 'https://ingco.co.id/Image/blog',

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // 'X-CSRF-TOKEN': 'CSRF-Token',
            // 'Content-Accept': 'application/json',
            // 'Authorization': 'Bearer <JSON Web Token>',
            // 'Access-Control-Allow-Headers': '*',
            // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Max-Age': 0
          },
        },
      },
    })
    const blankProduct = {
      nama_produk: null,
      kd_kategori: null,
      harga: null,
      spesifikasi: null,
      berat: null,
      garansi: null,
      diskon: null,
      avalaible: null,
      discontinue: null,
      deselected_tag: [],
    }

    const categoryOptions = ref([])
    const tagOptions = ref([])
    const getCategoryOptions = () => {
      service.index({ url: 'kategori', query: 'perPage=1000' }).then(res => {
        categoryOptions.value = res.data.data
      })
    }

    const guaranteeOptions = ref([])
    const getGuaranteeOptions = () => {
      service.index({ url: 'garansi', query: 'perPage=1000' }).then(res => {
        guaranteeOptions.value = res.data.data
        console.log(guaranteeOptions.value)
      })
    }

    const showLoading = ref(props.loading)
    const product = props.productData
      ? ref(props.productData)
      : ref(JSON.parse(JSON.stringify(blankProduct)))
    product.value.deselected_tag = []
    onMounted(() => {
      getCategoryOptions()
      getGuaranteeOptions()
      if (product.value !== null) {
        showLoading.value = false
      }
    })

    const resetShipment = () => {
      product.value = JSON.parse(JSON.stringify(blankProduct))
    }

    const defaultTime = () => {
      const now = new Date()
      return new Date().setHours(now.getHours() + 1, 0, 0, 0)
    }

    const notBeforeTime = date => {
      const now = new Date()
      return date.getHours() < now.getHours() + 1
    }

    const onSubmit = () => {
      showLoading.value = true
      product.value.id = product.value.kd_produk
      service
        .update({ url: 'produk', data: product.value })
        .then(res => {
          // window.location.reload()
          showLoading.value = true
          store
            .dispatch('app-produk/fetchProduk', {
              id: router.currentRoute.params.id,
            })
            .then(response => {
              product.value = response.data
              product.value.deselected_tag = []
            })
            .catch(error => {
              if (error.response.status === 404) {
                product.value = undefined
              }
            })
            .finally(() => {
              showLoading.value = false
            })
        })
        .catch(err => {})
        .finally(() => {
          showLoading.value = false
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetShipment)

    const fetchTags = async (query = null) => {
      try {
        const response = await service.index({ url: 'tags', query })
        // console.log(response.data)
        tagOptions.value = response.data.data
      } catch (error) {
        console.log(error)
      }
    }

    fetchTags()

    const onDeselectTag = tag => {
      product.value.deselected_tag.push(tag.id)
      console.log(product.value.deselected_tag)
    }

    const onSelectTag = tag => {
      const idx = product.value.deselected_tag.indexOf(tag.id)
      product.value.deselected_tag.splice(idx, 1)
      console.log(product.value.deselected_tag)
    }

    return {
      product,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      defaultTime,
      notBeforeTime,
      cekEditorConfig,
      categoryOptions,
      guaranteeOptions,
      showLoading,
      tagOptions,
      onSelectTag,
      onDeselectTag,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
