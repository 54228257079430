<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card id="images-product-section">
      <div class="d-flex justify-content-end">
        <b-button
          v-if="mode === 'show'"
          variant="primary"
          size="sm"
          @click="changeMode"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
        <b-button v-else variant="secondary" size="sm" @click="changeMode">
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <div class="d-flex">
        <div
          v-if="mode === 'show'"
          v-viewer="{ moveable: false }"
          class="images"
        >
          <b-img
            v-for="(image, idx) in imageList"
            :id="`avatar-${idx}`"
            :key="image.path"
            width="80"
            height="80"
            rounded="lg"
            class="m-1"
            :src="`${config.apiUrl}/${image.path}`"
            size="80"
          />
        </div>
        <div v-else class="d-flex">
          <div
            v-for="(image, idx) in imageList"
            :key="image.path"
            class="m-1"
            style="position: relative"
          >
            <b-img
              :id="`avatar-${idx}`"
              width="80"
              height="80"
              rounded="lg"
              :src="`${config.apiUrl}/${image.path}`"
              size="80"
            />
            <div
              class="d-flex justify-content-center align-items-center delete-view"
              style="
                background-color: #aba7a773;
                border-radius: 10px;
                position: absolute;
                top: 0;
                z-index: 10;
                height: 80px;
                width: 80px;
              "
              @click="deleteImage(image.id)"
            >
              <feather-icon icon="Trash2Icon" size="30" color="grey" />
            </div>
          </div>
        </div>
        <div
          id="add-image-btn"
          class="d-flex justify-content-center align-items-center m-1"
          style="
            width: 80px;
            height: 80px;
            border: 1px dashed grey;
            border-radius: 10px;
          "
          @click="addModal = true"
        >
          <feather-icon icon="PlusIcon" size="30" />
        </div>
      </div>
    </b-card>
    <b-modal v-model="addModal" centered size="xl" @ok="doAddImages">
      <drop-file @changed="onfilesChanged" />
    </b-modal>
  </b-overlay>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import VueViewer, { directive as viewer } from 'v-viewer'
import config from '@/config/config'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'
import DropFile from '@/views/components/dropfile/DropFile.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    VueViewer,
    viewer,
    BImg,
    BOverlay,
    DropFile,
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  props: {
    productData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup(props, context) {
    const imageList = ref(props.productData.images)
    const mode = ref('show')
    const showLoading = ref(false)
    const addModal = ref(false)
    const imageFiles = ref([])

    const changeMode = () => {
      if (mode.value === 'show') {
        mode.value = 'remove'
      } else {
        mode.value = 'show'
      }
    }

    const deleteImage = id => {
      Vue.swal({
        title: 'Are you sure to delete it?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, deleted it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          showLoading.value = true
          store
            .dispatch('app-produk/deleteImage', { id })
            .then(response => {
              Vue.swal({
                icon: 'success',
                title: 'Removed!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              imageList.value = response.data.data
            })
            .finally(() => {
              showLoading.value = false
            })
        } else if (result.dismiss === 'cancel') {
          Vue.swal({
            title: 'Cancelled',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
    const onfilesChanged = files => {
      imageFiles.value = files
      console.log(imageFiles.value)
    }

    const doAddImages = () => {
      showLoading.value = true
      const formData = new FormData()
      formData.append('kd_produk', props.productData.kd_produk)
      imageFiles.value.forEach((file, idx) => {
        formData.append('image_files[]', file)
      })

      store
        .dispatch('app-produk/addImages', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          Vue.swal({
            icon: 'success',
            title: 'Berhasil!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          imageList.value = response.data.data
        })
        .finally(() => {
          showLoading.value = false
        })
    }
    return {
      config,
      mode,
      changeMode,
      deleteImage,
      imageList,
      showLoading,
      addModal,
      onfilesChanged,
      doAddImages,
    }
  },
}
</script>

<style>
#images-product-section .b-avatar-badge {
  font-size: calc(18px) !important;
  background-color: red;
}

#images-product-section .b-avatar-badge:hover {
  font-size: calc(19px) !important;
}

#add-image-btn:hover {
  background-color: rgb(234, 234, 234);
}

.delete-view:hover {
  background-color: #aba7a7c4 !important;
}
</style>
