<template>
  <div class="main">
    <div
      class="dropzone-container"
      :style="isDragging && 'border-color: green;'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        id="fileInput"
        ref="file"
        type="file"
        multiple
        name="file"
        class="hidden-input"
        accept=".pdf,.jpg,.jpeg,.png"
        @change="onChange"
      />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>
      <div v-if="files.length" class="preview-container mt-4">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="generateURL(file)" />
            <p>{{ file.name }} - {{ Math.round(file.size / 1000) + 'kb' }}</p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              title="Remove file"
              @click="remove(files.indexOf(file))"
            >
              <b>×</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emit: ['changed'],
  data() {
    return {
      isDragging: false,
      files: [],
    }
  },
  methods: {
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      console.log(e.dataTransfer.files)
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
    remove(i) {
      this.files.splice(i, 1)
      this.$emit('changed', this.files)
    },
    generateURL(file) {
      const fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    },
    onChange() {
      const self = this
      const incomingFiles = Array.from(this.$refs.file.files)
      const fileExist = self.files.some(r =>
        incomingFiles.some(file => file.name === r.name && file.size === r.size)
      )
      if (fileExist) {
        self.showMessage = true
        alert('New upload contains files that already exist')
      } else {
        self.files.push(...incomingFiles)
      }

      this.$emit('changed', self.files)
    },
    // uploadFiles() {
    //   const { files } = this
    //   const formData = new FormData()
    //   files.forEach(file => {
    //     formData.append('selectedFiles', file)
    //   })

    //   axios({
    //     method: 'POST',
    //     url: 'http://path/to/api/upload-files',
    //     data: formData,
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    // },
  },
}
</script>
<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 400px;
  height: 70vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 2px dashed;
  border-color: #9e9e9e;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>
